
import Vue from "vue";
import MovementsHistoryList from "@/components/subviews/movements-history/movements-history-list.vue";
import MovementsHistoryMaintenance from "@/components/subviews/movements-history/movements-history-maintenance.vue";
import IConta from "@/lib/interfaces/conta";

export default Vue.extend({
  name: "MovementsHistory",

  components: { MovementsHistoryList, MovementsHistoryMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      conta: {} as IConta,
    };
  },
});
