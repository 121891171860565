
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADate from "@/lib/a-date";
import { currency } from "@/lib/a-format";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import IConta from "@/lib/interfaces/conta";
import IPerson from "@/lib/interfaces/person";
import ICostCenter from "@/lib/interfaces/cost-center";
import { required } from "@/lib/validations";

export default Vue.extend({
  name: "MovementsHistoryList",

  components: { vAlertMessage, ABtn, ATextField, ADataTable, ACard },

  props: {
    conta: {
      type: Object as PropType<IConta>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      valid: true,

      total: 0,
      search: "",
      startDate: new ADate().dateString,
      endDate: new ADate().dateString,

      options: {} as DataOptions,
      contas: new Array<IConta>(),
      costCenters: new Array<ICostCenter>(),
      people: new Array<IPerson>(),

      headers: [
        {
          text: "Centro de custo",
          value: "cCentroCusto",
          sortable: false,
          align: "start",
        },
        {
          text: "Empresa",
          value: "cEmpresa",
          sortable: false,
          align: "start",
        },
        {
          text: "Data lançamento",
          value: "cDataLancamento",
          sortable: false,
          align: "center",
        },
        {
          text: "Data criação",
          value: "cDataCriacao",
          sortable: false,
          align: "center",
        },
        {
          text: "Data atualização",
          value: "cDataAtualizacao",
          sortable: false,
          align: "center",
        },
        {
          text: "Valor",
          value: "cValor",
          sortable: false,
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getContas().then();
    },

    options: {
      deep: true,
      handler() {
        this.getContas().then();
      },
    },
  },

  computed: {
    cContas(): Array<Record<string, unknown>> {
      return this.contas.map((conta) => ({
        ...conta,

        cCentroCusto:
          this.costCenters.find(
            (costCenter) => costCenter.id === conta.centro_custo_id
          )?.descricao ?? conta.centro_custo_id,

        cEmpresa:
          this.people.find((person) => person.id === conta.empresa_id)
            ?.nome_raz ?? conta.empresa_id,

        cDataLancamento: ADate.parse(conta.data_lancamento).localeDateString,
        cDataCriacao: ADate.parse(conta.data_criacao).localeDateTimeString,
        cDataAtualizacao: ADate.parse(conta.data_atualizacao)
          .localeDateTimeString,
        cValor: currency(conta.valor, "BRL", true),
      }));
    },
  },

  mounted() {
    this.getCostCenters().then();
    this.getPeople().then();
  },

  methods: {
    required,

    async openConta(id: string) {
      const conta = this.contas.find((conta) => conta.id === id);
      this.$emit("update:conta", { ...conta });
    },

    async getContas() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("contas.read", {
          search: this.search,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
          startDate: this.startDate,
          endDate: this.endDate,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.contas = rsp.getTable("contas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getCostCenters() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("centro_de_custo.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.costCenters = rsp.getTable("centro_custo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPeople() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.people = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
