export function currency(
  value: string | number,
  currency: "EUR" | "USD" | "BRL",
  showSymbol?: boolean
): string {
  if (typeof value === "string") value = Number(value);

  value = value.toLocaleString("pt-BR", {
    style: "currency",
    currency,
  });

  if (!showSymbol) value = value.replaceAll(/[^\d.,]/g, "");

  return value;
}

export function cpf(value: string): string {
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
}

export function cnpj(value: string): string {
  return value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
}

export function cpfCnpj(value: string): string {
  return value.length === 11 ? cpf(value) : cnpj(value);
}

export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
