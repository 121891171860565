
import Vue, { PropType } from "vue";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ADate from "@/lib/a-date";
import { currency } from "@/lib/a-format";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import IConta from "@/lib/interfaces/conta";
import IContaItem from "@/lib/interfaces/conta-item";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import IPerson from "@/lib/interfaces/person";
import IAccountPlan from "@/lib/interfaces/account-plan";
import ICostCenter from "@/lib/interfaces/cost-center";

export default Vue.extend({
  name: "MovementsHistoryMaintenance",

  components: { vAlertMessage, ADataTable, ACard },

  props: {
    conta: {
      type: Object as PropType<IConta>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      CREDITO: "1",
      DEBITO: "2",
      DEBITO_CREDITO: "3",

      datetime: "",

      headers: [
        { text: "Plano de conta", value: "cAccountPlan" },
        { text: "Pessoa", value: "cPerson" },
        { text: "Descrição", value: "descricao" },
        { text: "Tipo", value: "cTipo" },
        { text: "Crédito", value: "cCredito", align: "right" },
        { text: "Débito", value: "cDebito", align: "right" },
      ],
      contaItens: [] as Array<IContaItem>,
      people: [] as Array<IPerson>,
      accountPlans: [] as Array<IAccountPlan>,
      costCenters: [] as Array<ICostCenter>,

      resultsHeaders: [
        { text: "Total entradas", value: "creditos", align: "right" },
        { text: "Total saídas", value: "debitos", align: "right" },
        { text: "Saldo", value: "saldo", align: "right" },
      ],
    };
  },

  watch: {
    conta: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.getContaItens().then(() => {
          this.datetime = new ADate().localeDateTimeString;
        });
      },
    },
  },

  computed: {
    cContaItens(): Record<string, string>[] {
      return this.contaItens.map((item) => ({
        ...item,

        cPerson:
          this.people.find((person) => person.id === item.pessoa_id)
            ?.nome_raz ?? item.pessoa_id,

        cAccountPlan:
          this.accountPlans.find(
            (accountPlan) => accountPlan.id === item.plano_de_contas_id
          )?.descricao ?? item.plano_de_contas_id,

        cDataVencimento: ADate.parse(item.data_vencimento).localeDateString,
        cDataFechamento: ADate.parse(item.data_fechamento).localeDateString,

        cCredito: item.tipo == "1" ? currency(item.valor, "BRL", true) : "",
        cDebito: item.tipo == "2" ? currency(item.valor, "BRL", true) : "",
      }));
    },

    cResults(): Record<string, string>[] {
      const creditos = this.contaItens
        .filter((contaItem) => contaItem.tipo === this.CREDITO)
        .reduce((acc, contaItem) => (acc += Number(contaItem.valor)), 0);

      const debitos = this.contaItens
        .filter((contaItem) => contaItem.tipo === this.DEBITO)
        .reduce((acc, contaItem) => (acc += Number(contaItem.valor)), 0);

      const saldo = creditos - debitos;

      return [
        {
          creditos: `R$ ${currency(creditos, "BRL")}`,
          debitos: `R$ ${currency(debitos, "BRL")}`,
          saldo: `R$ ${saldo < 0 ? "-" : ""}${currency(saldo, "BRL")}`,
        },
      ];
    },

    cDataLancamento(): string {
      if (!this.conta.data_lancamento) return "";

      return ADate.parse(this.conta.data_lancamento).localeDateString;
    },

    cCostCenter(): string {
      if (!this.conta.centro_custo_id) return "";

      return (
        this.costCenters.find(
          (costCenter) => costCenter.id === this.conta.centro_custo_id
        )?.descricao ?? this.conta.centro_custo_id
      );
    },
  },

  mounted() {
    this.getCostCenters().then();
    this.getAccountPlans().then();
    this.getPeople().then();
  },

  methods: {
    async getContaItens() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("conta_itens.read", {
          contaId: this.conta.id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.contaItens = rsp.getTable("conta_itens")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getCostCenters() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("centro_de_custo.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.costCenters = rsp.getTable("centro_custo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getAccountPlans() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_de_conta.read", {
          sortBy: "nivel",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.accountPlans = rsp.getTable("plano_contas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPeople() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.people = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
